.schedule {
    position: relative;
    text-align: left;
    margin: 10px auto;

    .hourContainer {
        border-left: 1px solid #e5e5e5;
        position: relative;
        color: #000;
        overflow: hidden;
    }

    .hourContainer .hourSection {

        border-right: 2px solid #e5e5e5;
        border-top: 2px solid #e5e5e5;

        display: inline-block;
        position: relative;
        height: 65px;
        width: 10%;
        box-sizing: border-box;
    }

    .hourContainer .hourSection .hourLabel {
        left: 2px;
        position: absolute;
        top: 45px;
    }

    .hourContainer .hourSection .hourVerticalDivider {
        left: 50%;
        border-left: 1px solid #e5e5e5;

        position: absolute;
        top: 0;
        bottom: 40%;
    }

    .hourContainer .hourSection .hourHorizontalDivider {
        border-top: 1px solid #e5e5e5;
        position: absolute;
        bottom: 40%;
        width: 100%;
    }

    .hourContainer .nowSection {
        background-color: darkorange;
        height: 99%;
        position: absolute;
        top: 1px;
        width: 3px;
        z-index: 5;
    }

    .hourContainer .freeBusySection {
        height: 54.5%;
        position: absolute;
        top: 1px;
    }
}