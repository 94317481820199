.subordinates {
    padding: 20;
    justify-content: flex-start;
    padding-top: 0;
}

.content {
    display: grid;
    gap: 16px;
    padding-bottom: 100;
}