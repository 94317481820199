.button {
    color: darkorange;
}

.container {
    display: flex;
    flex: auto;
    justify-content: space-between;
    color: darkorange;
    height: 35px;
}