.profile {
    flex-grow: 1;
    padding: 20px;
    padding-top: 0px;
    justify-content: space-between;
    align-content: flex-start;
}

.avatar {
    align-self: center;
    padding-top: 4;
    display: grid;
    justify-content: center;
}

.content {
    padding-top: 8;
}

.contacts {
    padding-bottom: 8;
}

.cellButton {
    text-decoration: none;
    padding-bottom: 8;
}

.name {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
    color: darkorange;
    padding: 8;
    padding-bottom: 6;
}

.jobTitle {
    text-align: center;
    font-size: large;
    color: gray;
}

.bottomButtons {
    display: grid;
    padding: 20;
    padding-top: 0;
    gap: 10px;
}

.accessoryButton {
    color: #ffe3d7;
}

.teamsButtons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 5;
    padding-bottom: 5;
}

.callButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: stretch;
}

.navbarButtons {
    display: flex;
    gap: 8px;
}

.help {
    color: #13AB61;
}

a {
    width: -webkit-fill-available;
}