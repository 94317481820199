.authorization {
    justify-content: space-between;
    align-items: stretch;
    padding: 20;
    padding-bottom: 40;
}

.logo {
    flex-grow: 1;
    width: 60%;
    align-self: center;
}

.loginButton {
    display: grid;
}