.container {
    justify-content: center;
    align-items: center;
    padding: 20;
    gap: 60px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.emoji {
    font-size: 40;
}