.header {
    text-align: left;
    font-size: 14;
    color: gray;
    padding-top: 8px;
}

.peopleList {
    gap: 12px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
}
