.buttonEnabled {
    color: darkorange !important;
    border-color: darkorange !important;
    height: 40px !important;
    width: -webkit-fill-available;
}

.buttonDisabled {
    color: transparent !important;
    border-color: transparent !important;
    background-color: #fff3e5 !important;
    height: 40px !important;
    width: -webkit-fill-available;
}