.peopleSearch {
    gap: 12px;
    justify-content: flex-start;
    padding: 20;
}

.searchBox {
    flex: none;
}

.content {
    padding-bottom: 100;
}