.cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 12px;
}

.icon {
    width: 24;
    height: 24;
    color: darkorange;
}

.content {
    text-align: left;
    flex-grow: 1;
    color: black;
}